import "./index.css";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

import * as React from "react";

// TODO: delete this page

const PRIVACY_POLICY_URL = "https://faintlines.com/privacy";

export default function PrivacyPage() {
    return (
        <Layout>
            <title>{"Privacy Policy | Peek a Phone"}</title>
            <Helmet>
                <meta
                    http-equiv="refresh"
                    content={`0; url=${PRIVACY_POLICY_URL}`}
                />
            </Helmet>
            <h1>{"Privacy Policy"}</h1>
            <p>
                <a href={PRIVACY_POLICY_URL}>{"Click here"}</a>
                {" if you are not being redirected."}
            </p>
        </Layout>
    );
}
